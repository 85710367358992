@font-face {
    src: url('../font/pfdintextcomppro-thin-webfont.woff2');
    font-family: 'pf_din_text_comp_thin';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    src: url('../font/pfdintextcomppro-regular-webfont.woff2');
    font-family: 'pf_din_text_comp_regular';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    src: url('../font/pfdintextcomppro-xthin-webfont.woff2');
    font-family: 'pf_din_text_comp_xthin';
    font-weight: normal;
    font-style: normal;
}
