@mixin sidebar-common($side) {
    width: 100%;
    background-color: var(--bs-white);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    height: calc(100vh - #{$navbarHeight});
    position: fixed;
    @if $side == left {
        border-right: 1px solid #ced4da;
    } @else if $side == right {
        border-left: 1px solid #ced4da;
    }
    #{$side}: 0px;
    transition: $side $transition-duration;
}

@mixin vh() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
