.react-bootstrap-table {
    .table td, .table th {
        vertical-align: middle;
        padding: 0.75rem 0.75rem;
    }
}

.react-bootstrap-table-pagination-list {
    .pagination {
        justify-content: flex-end;
    }
}

.react-bootstrap-table-pagination{
    .col-xs-6:first-child{
        opacity: 0;
    }
}
