@import './assets/scss/fonts';
@import './assets/scss/variables';
@import './assets/scss/mixins';
@import "~bootstrap/scss/bootstrap";
@import './assets/scss/rbt';
@import './assets/scss/bootstrap';

.no-sensors {
    color: red;

    input {
        color: red;
    }
}

.link-container {
    max-height: calc(100vh - 222px);

    @media screen and (max-width: $md - 1) {
        max-height: calc(100vh - 192px);
    }

    scrollbar-width: none;
    overflow-y: scroll;
    flex-grow: 1;

    &::-webkit-scrollbar {
        display: none;
    }
}

.sidebarConfig-wrapper {
    $width: 440px;
    width: $width;
    transition: $transition-duration;

    @media screen and (max-width: $lg - 1) {
        width: 0;
    }

    &--hide {
        width: 0;
    }

    .sidebarConfig {
        $width: 440px !important;
        overflow: auto;
    
        @include sidebar-common(right);
        max-width: $width;
    
        &--hide{
            right: -$width;
        }
    }

    .toggleSidebar {
        position: absolute;
        right: 20px;
        top: 26px;

        @media screen and (max-width: $md - 1) {
            top: 21px;
        }

    }
}

.sidebar-wrapper {
    $width: 300px;
    left: 0;
    transition: width $transition-duration;
    width: $width;

    @media screen and (max-width: $lg - 1) {
        width: 0;
    }

    &--hide {
        width: 0;
    }

    .nav-link {
        position: relative;

        &.active {
            &::before {
                content: '';
                width: 10px;
                height: 26px;
                background: url("./assets/active-link.svg") no-repeat center center;
                position: absolute;
                left: 0;
            }
        }
    }

    .sidebar {
        $width: 300px;
    
        @include sidebar-common(left);
        max-width: $width;
        
        &--hide{
            left: -$width;
        }
    }
}

.cancelIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.toggleSidebar {
    z-index: 100;
    cursor: pointer;
    background: none !important;
    border: none;
    padding: 0;
    
    &:focus {
        box-shadow: none !important;
    }

    &--left {
        position: fixed;
        left: 1rem;
        top: 95px;

        @media screen and (max-width: $md - 1) {
            top: 89px;
        }
    }

    &--right {
        position: fixed;
        right: 1rem;
        top: 94px;

        @media screen and (max-width: $md - 1) {
            top: 89px;
        }
    }
}
.legend_icon{
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 0;
    width: 14px;
    outline: none !important;
    box-shadow: none !important;
}

.legend {
    max-width: 630px;
    background: var(--bs-white);
    transition: all .25s;
    z-index: 101;

    &__hide-icon {
        padding: 0;
        top: 0;
        right: 10px;
        position: absolute;
        width: 14px;
        outline: none !important;
        box-shadow: none !important;
        z-index: 102;
    }

    &--hide {
        left: -630px;
    }

    @media screen and (max-width: $sm - 1) {
        &__toggle {
            padding: 0;
            top: 0;
            right: 0;
            position: absolute;
            height: 14px;
            width: 100%;
            outline: none !important;
            box-shadow: none !important;
        }
    
        &--hide {
            left: 0;
            top: calc(100vh - 14px);

            &:hover {
                left: 0;
            }
        }
    }
}

.silos-col {
    overflow-x: hidden;
    margin-bottom: 1rem;

    & > div {
        overflow-x: auto;
    }
}

.silos-container {
    padding: 0 1px;

    form {
        height: 100%;
    }

    input {
        font-family: monospace;
        background: transparent;
        font-size: 14px;
    }

    &.rect {
        width: 100%;
        
        table {
            border-collapse: collapse;
        }

        input {
            border: none;
        }

        form {
            width: fit-content;
            min-width: 100%;
        }
    }

    &.round {
        position: relative;
    
        .big {
            left:0;
            right:0;
            margin-left:auto;
            margin-right:auto;
            position: absolute;
            background-color: #f5f5f5;
            border: 1px solid #000;
            border-radius: 50%;
            transform: translateY(-50%);
            top: 50%;
        }
    
        .little {
            position: absolute;
            height: 35px;
            width: 35px;
            background: #fCfCfC;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid #0f0f0f;
            outline: none;
            font-size: 10px;
        }
    
        .center {
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            transform: translateY(-50%);
            top: 50%;
            z-index: 101;
        }

        .control {
            width: 100%;
            align-self: flex-end;
        }
    }

    .control {
        align-items: center;
        margin-top: 5px;
        p {
            position: sticky;
            left: 10px;
            z-index: 50;
        }
        button {
            position: sticky;
            right: 0;
            z-index: 50;
        }
    }
}

.objectItemContainer {
    input {
        width: 100%;
        text-align: center;

        &:focus {
            outline: none;
        }
    }
    td {
        min-width: 50px;
        width: 50px;
        height: 30px;
    }
    textarea {
        &:focus {
            outline: none;
        }
    }
}

.customToast {
    position: absolute;
    top: 80px;
    right: 10px;
    width: 260px;
    z-index: 10;

    .toast-header, .toast-body {
        background-color: #ffdbdb;
    }
    
    .toast-header {
        border-bottom: none;
    }
}

.userDropdown {
    width: min-content;

    @media screen and (max-width: $md - 1) {
        display: none;
    }

    .dropdown-menu {
        left: -90px; 
    }

    .dropdown-toggle {
        padding: 8px 16px;

        &::after {
            opacity: 0;
        }
    }
}


.sensorList-container {
    min-height: 25rem;
    height: calc(100vh - 370px);
    overflow-y: scroll;
    scrollbar-color: var(--bs-primary) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color:var(--bs-primary);
    }
}


.showPasswordBtn {
    background: none;
    border: 1px solid #ced4da;

    &:hover, &:active, &:focus {
        background-color: inherit !important;
    }

    &:focus {
        box-shadow: none;
    }
}

$checkbox-background: #fff;
$checkbox-border: var(--bs-primary);
$checkbox-checked: $checkbox-border;

.customCheckboxRadio {
    padding-left: 0;

    input[type="checkbox"], input[type="radio"] {
        @include vh();
        + label {
            position: relative;
            padding-left: 1.5rem;
            user-select: none;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0.9rem;
                height: 0.9rem;
                border: 1px solid $checkbox-border;
                border-radius: 0.2rem;
                background: $checkbox-background;
                text-align: center;
                transition: background 200ms ease-out;
            }
            &:after {
                content: "";
                position: absolute;
                transform: scale(0);
                transition: transform 200ms ease-out;
            }
        }
    }

    input[type="checkbox"] {
        + label {
            &:after {
                background-color: transparent;
                content: "";
                display: block;
                position: absolute;
                left: 0.32rem;
                top: 0.15rem;
                width: 0.3rem;
                height: 0.5rem;
                opacity: 0.2;
                border-bottom: 2px solid $checkbox-checked;
                border-right: 2px solid $checkbox-checked;
                transform: rotate(45deg);
                transition: border-color 0.3s ease;
            }
        }
        &:checked {
            + label {
                &:before, &:after {
                    content: "";
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }  

    input[type="radio"] {
        + label {
            &:before, &:after {
                border-radius: 50%;
            }
            &:after {
                left: 0.2rem;
                top: 0.2rem;
                width: 0.5rem;
                height: 0.5rem;
            }
        }
        &:checked {
            + label {
                &:before {
                    animation: borderscale 300ms ease-in;
                }
                &:after {
                    background: $checkbox-checked;
                    transform: scale(1);
                }
            }
        }
    }
}
  

.custom-switch .custom-control-label::before {
    top: 0;
}

.custom-switch .custom-control-label::after {
    top: 2px;
}

/* loader */

.customLoader {
    position: fixed;
    height: calc(100vh - #{$navbarHeight});
    width: 100%;
    left: 0;
    top: $navbarHeight;
    z-index: 10000000;
    width: 100%;
    background-color: #ffffffb3;
}

.customCheckboxRadioTable {
    input[type="checkbox"], input[type="radio"] {
        + label {
            &:before {
                top: -14px;   
            }
        }
    }

    input[type="checkbox"] {
        + label {
            &:after {
                top: -0.5rem;
            }
        }
    }   
}

@media screen and (max-width: $md - 1) {
    .navbar--mobile {
        position: fixed;
        top: $navbarHeight;
        left: 0;
        background-color: var(--bs-primary);
        width: 100%;
        padding: 15px;
    }
}

.silosCard{
    width: 346px;
    margin: 12px;
}

.lights {
    border: 6px solid var(--bs-lightGrey);
    border-radius: 10px;
    padding: 8px;
    width: max-content;
    margin: 0 auto;

    &__circle {
        background-color: var(--bs-lightGrey);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 4px;

        &--warning {background-color: var(--bs-warning);}
        &--danger {background-color: var(--bs-danger);}
        &--primary {background-color: var(--bs-primary);}
    }
}

.silos-background, .map-background{
    width: 100%;
    height: calc(100% - 45px);
    background-position: center;
    background-repeat: no-repeat;
}

.object-card{
    width: 320px;
    min-height: 275px;
    margin-right: 32px;
    border-color: var(--bs-primary),
}

.weigher-container{
    &::after{
        content: '';
        display: block;
        height: 1px;
        background: var(--bs-primary);  
        margin: 8px 0;
    }
}

.pagination .page-item.active .page-link {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.pagination .page-link{
    color: var(--bs-primary);
}

.silos-view-switch{
    padding: 2px 16px;
    
    &:focus{
        box-shadow: none !important;
    }
}

.nav-link{
    color: var(--bs-primary);

    &:hover {
        color: var(--bs-primary);
    }

    &:focus {
        color: var(--bs-primary);
    }
}


.loading{
    text-transform:uppercase;
   font-weight:bold;
   font-size:70px;
   text-align:center;
   height:70px;
   line-height:70px;
   position:absolute;
   left:50%;
   top:50%;
   transform: translate(-50%, -50%);
   display:block;
 }

 .grain-loader{
    background-image:url('./assets//background-loader.svg');
    background-clip: text;
    color:#174b0080;
    text-shadow:0px 0px #ffffff0f;
    animation:grain-animation 1s infinite linear; //loading-animation 10s infinite;
    background-size:200px 120px;
    background-repeat:repeat-x;
    opacity:1;
 }

 @keyframes grain-animation{
    0%{background-position:0 bottom};
    100%{background-position:200px bottom};
  }
  @keyframes loading-animation{
    0%{background-size:200px 0px};
    100%{background-size:200px 200px};
  }

  .kt-info-item{
    width: 90px;
  }

  .userDropdown {
    .dropdown-menu{
        left: -135px;
    }
  }
