/* global Bootstrap overrides */

html {
    font-size: 18px;
    @media screen and (min-width: $md) {
        font-size: 20px;
    }
}

body {
    font-family: "pf_din_text_comp_regular", "source_sans_prolight", "Trebuchet MS", "Lucida Grande", Arial, Helvetica, sans-serif;
}

label {
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
}

.dropdown {
    .dropdown-menu {
        .btn-group {
            display: none;
        }
    }
}

.dropdown-toggle {
    line-height: 0;
}

.navbar {
    height: $navbarHeight;
}
