$transition-duration: .5s;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$navbarHeight: 72px;

$theme-colors: (
    "primary": #174B00,
    "warning": #FFF034,
    "danger": #FF5733,
    "good":  #00B900,
    "grey": #9A9A9A,
    "lightGrey": #e3d9d9,
);

$grid-breakpoints: (
  xs: 0,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl
);
